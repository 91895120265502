<template>
  <!-- 我的考核指标--GS任务列表--任务详情 -->
  <div class="addFrom">
    <van-nav-bar
      :title="title"
      left-text
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <!-- <van-button class="foot" type="info" @click="ok">确定</van-button> -->
    <van-cell title="考核周期" :value="record.khtimename" />
    <van-cell title="申请日期" :value="record.reqdate" />
    <van-cell title="任务名称" :value="record.taskname" />
    <van-cell title="评分方式" :value="record.pftype" />
    <van-cell title="任务来源" :value="record.tasksource" />
    <van-cell title="回顾及反馈频率" :value="record.hgyfk" />
    <van-cell title="责任人" :value="record.empname" />
    <van-cell title="审批人" :value="record.xd_empname" />
    <van-cell title="任务级别" :value="record.tasklevel" />
    <van-cell title="权重系数" :value="record.taskper" />
    <van-cell title="目标值" :value="record.tgvalue" />
    <van-cell title="数据提供人" :value="record.datasupply" />
    <van-cell title="排序号" :value="record.sqnum" />
    <van-cell title="计划完成时间" :value="record.planfinishdate" />
    <van-cell title="里程碑描述" :value="record.lcbms" />
    <van-cell title="评分标准" :value="record.pfbz" />
    <van-cell title="验收人1" :value="record.ys_empname1" />
    <van-cell title="验收人1评分占比" :value="record.ys_per1" />
    <van-cell title="备注" :value="record.remark" />
    <van-cell title="完成结果描述" :value="record.finishdesp" />
    <div class="footer_btn">
      <van-button class="backButton" type="info" @click="onClickLeft"
        >返回</van-button
      >
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant'
import { getGsTaskRecordInfo } from '@api/wxjx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  data() {
    let khtimeid = this.$route.query.khtimeid
    return {
      khtimeid,
      userInfo,
      autoid: this.$route.query.autoid,
      title: this.$route.query.title,
      record: {},
      showDia: false,
      checkmemo: ''
    }
  },
  created() {
    this.khtimeid = this.$route.query.khtimeid
    this.autoid = this.$route.query.autoid
    this.title = this.$route.query.title
    this.getGsTaskRecordInfo(this.autoid)
  },
  methods: {
    onClickLeft() {     
      this.$router.push({
        path: '/jxMyAssTarget',
        query: {
          khtimeid: this.khtimeid
        }
      })
    },
    getGsTaskRecordInfo(autoid) {
      getGsTaskRecordInfo({
        autoid: this.autoid
      }).then(res => {
        this.record = res.data[0]
      })
    }
  }
}
</script>
<style lang="less" scoped>
.addFrom {
  width: 100vw;
  padding-bottom: 30px;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .foot {
    width: calc(100vw - 30px);
    margin: 10px 15px;
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    .van-button {
      width: 60%;
      margin: 0 2%;
      border-radius: 9px;
      // width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
    .backButton {
      width: 100%;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #999;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
      border-color: #999;
    }
  }
}
</style>
